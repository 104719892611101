import url from './url';

/**
 * ajax 基础框架
 * { type url data async timeout success error json}
 */
const ajax = function (opt) {
    opt.type = opt.type || 'get'
    opt.async = opt.async || true
    let params = url.format(opt.data);
    let timer = void 0;
    //1.创建Ajax对象 IE7+, Firefox, Chrome, Opera, Safari : IE6-
    let oAjax = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject("Microsoft.XMLHTTP")
    //2.连接服务器
    if('get' === opt.type.toLowerCase()) {
        if(params) params = '?' + params
        oAjax.open(opt.type, opt.url + params, opt.async);
        //3.发送请求
        oAjax.send();
    } else {
        oAjax.open(opt.type, opt.url, opt.async);
        oAjax.setRequestHeader("Content-type","application/x-www-form-urlencoded");
        oAjax.send(params);
    }
    //4.接收返回
    oAjax.onreadystatechange = function () {
        if (4 === oAjax.readyState) {
            // 正常接收数据时关闭定时器
            clearInterval(timer);
            if (200 === oAjax.status) {
                // 给成功时的方法传入一个参数
                if (opt.success) opt.success(opt.json ?  JSON.parse(oAjax.responseText) : oAjax.responseText);
            } else {
                // 判断是否传入了失败时的方法
                if (opt.error) opt.error(oAjax.status);
            }
        }
    };
    // 判断是否传入了超时时间
    if (opt.timeout) {
        timer = setInterval(function() {
            // 中断请求
            oAjax.abort();
            clearInterval(timer);
        }, opt.timeout)
    }
};

const request = {
    ajax: ajax,
    get: (url, success) => {
        ajax({type: 'get', url: url, success: success})
    },
    post: (url, data, success) => {
        ajax({type: 'post', url: url, data: data, success: success})
    },
    getJson: (url, success) => {
        ajax({type: 'get', url: url, json: true, success: success})
    }
}

export default request;
