const url = {
    /**
     * 对象转 url get传值
     * {"name": "abc", "pwd": "123"}  => 'name=abc&pwd=123'
     */
    format: function (opt = {}) {
        let res = [];
        // 部分api传入多余的参数会导致请求失败
        // opt.time = new Date().getTime();
        for (let key in opt) {
            res.push(`${encodeURIComponent(key)}=${encodeURIComponent(opt[key])}`);
        }
        return res.join('&');
    },

    // 获取地址栏中的参数
    query: function () {
        var params = {};
        if (window.location == null) return params;
        var name,value; 
        var str = window.location.href; //取得整个地址栏
        var num = str.indexOf("?") 
        str = str.substring(num + 1); //取得所有参数   stringvar.substr(start [, length ]

        var arr = str.split("&"); //各个参数放到数组里
        for(var i = 0, len = arr.length; i < len; i++) {
            num = arr[i].indexOf("="); 
            if (num > 0) { 
                name = arr[i].substring(0, num);
                value = arr[i].substring(num + 1);
                params[name] = value;
            }
        }
        return params;
    },

    /**
     * 获取 url 详情
     * @param  {String} url               url地址
     * @param  {Boolean} parseQueryString 是否将查询内容转换为对象
     * @return {Object}                   格式化对象
     */
    parse: function (url, parseQueryString) {
        let opt = {};
        // 先从 ? 开始分割，避免查询中包含网址时结果异常
        url = url.split('?');
        let home = url[0];
        let index = home.indexOf('://');
        // 路径分割点
        let pathindex = index > -1 ? index + 3 : 0;
        pathindex = home.indexOf('/', pathindex);
        opt.home = home.substring(0, pathindex);
        opt.pathname = home.substring(pathindex);
        let query = url[1];
        if (index > -1) {
            opt.protocol = opt.home.substring(0, index);
            home = opt.home.substring(index + 3).split(':');
        } else {
            opt.protocol = undefined;
            home = opt.home.split(':');
        }
        opt.hostname = home[0];
        opt.port = home[1];
        // 查询内容
        opt.query = parseQueryString ? {} : query;
        if (parseQueryString && query) {
            query = query.split('&');
            for (let i = 0, len = query.length; i < len; i++) {
                let t = query[i].split('=');
                opt.query[t[0]] = t[1];
            }
        }
        return opt;
    }
}

export default url;