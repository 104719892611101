import request from "../src/ajax";

function startTime() {
    var ymd = document.getElementById("ymd");
    var hms = document.getElementById("hms");
    var today = new Array('星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六');
    var date = new Date();
    var year = date.getFullYear(); //当前年份
    var month = date.getMonth(); //当前月份
    var data = date.getDate(); //天
    var week = today[date.getDay()];//星期
    var hours = date.getHours(); //小时
    var minute = date.getMinutes(); //分
    var second = date.getSeconds(); //秒
    var data = year + "年" + checkTime((month + 1)) + "月" + checkTime(data) + "日" + "&#8195;" + week;
    var time = checkTime(hours) + ":" + checkTime(minute) + ":" + checkTime(second);
    ymd.innerHTML = data;
    hms.innerHTML = time;
    setTimeout(startTime, 1000); //每一秒中重新加载startTime()方法
}
startTime();

// 补位 当某个字段不是两位数时补 0
function checkTime(str) {
    var num;
    str >= 10 ? num = str : num = "0" + str;
    return num;
}

// 调用百度查询
var input = document.getElementById("text");
function baidu() {
    var url = "http://www.baidu.com/s?wd=" + input.value;
    window.open(url);
}
document.getElementById('search').onclick = baidu

// 回车触发查询
input.addEventListener("keyup", function (event) {
    event.preventDefault();
    if (event.key === 'Entry') {
        baidu()
    }
});

// 通过天行API获取天气
function geteveryday(city, day) {
    request.getJson(`http://api.tianapi.com/tianqi/index?key=6a8b365e459f2650bfc43e272122d31d&city=${city}&type=${day}`, function(data) {
        if (data.code == "200") {
            var newslist = data.newslist[0]
            document.getElementById("everyday").innerHTML = newslist.tips;
            document.getElementById("weather").innerHTML = newslist.area + " " + newslist.weather + " " + newslist.real;
        } else {
            // 切换天气 api
            getWeather('wuhan')
        }
    })
    getCouplet()
}
geteveryday('101200105', '1')

// 通过天行API获取对联
function getCouplet() {
    request.getJson('http://api.tianapi.com/duilian/index?key=6a8b365e459f2650bfc43e272122d31d', function(data) {
        if (data.code == "200") {
            var newslist = data.newslist[0]
            document.getElementById("getCouplet").innerHTML = newslist.content;
        }
    })
}

// 通过心知天气api获取天气
function getWeather(area) {
    request.getJson(`https://api.seniverse.com/v3/weather/now.json?key=SQqVOJXtYXk7n_ms5&location=${area}`, function(res) {
        var info = res.results[0];
        document.getElementById("weather").innerHTML = info.location.name+"  "+info.now.text+"  " +info.now.temperature+"℃";
    })
}

function getIp() {
    request.getJson('https://www.mxnzp.com/api/ip/self?app_id=dnmvvtmdgdnfvkik&app_secret=WDExOUdVT0RMNGY4aW9jSndJdlU0UT09', function(data) {
        if(data.code == 1) {
            document.getElementById('remoteip').innerHTML = '来自' + data.data.desc.replace(' ', '') + '的朋友，您好！';
        }
    })
}
getIp()